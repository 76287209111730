<template>
  <q-header elevated>
    <q-toolbar>
      <q-toolbar-title class="q-my-xs">
        <q-img
          v-show="false"
          :src="
            certifiedPartner
              ? 'https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/logo/logo-certified.png'
              : 'https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/logo/rgb_improvers_white.png'
          "
          style="max-width: 150px"
        ></q-img>
      </q-toolbar-title>
    </q-toolbar>
  </q-header>
  <div
    class="row justify-between q-pa-md"
    v-if="terugVisible || afboekenVisible"
  >
    <div class="col-4">
      <q-btn
        style="width: 50px"
        class="float-left"
        color="primary"
        label="Terug"
        no-caps
        @click="terug()"
        v-if="terugVisible"
      />
    </div>
    <div class="col-4">
      <q-btn
        style="width: 75px"
        class="float-right"
        color="primary"
        label="Afboeken"
        no-caps
        @click="afboeken()"
        v-if="afboekenVisible"
      />
    </div>
  </div>
  <div class="q-pa-md row q-gutter-md">
    <q-stepper
      flat
      animated
      v-model="step"
      header-nav
      vertical
      ref="stepper"
      color="primary"
      class="q-pa-none"
      :class="colSize"
    >
      <q-card>
        <q-step
          :name="1"
          title="Aansluiting Selecteren"
          icon="electrical_services"
          :done="step > 1"
          :header-nav="step > 1 && !readonly"
        >
          <Address style="margin-left: -26px" />
        </q-step>
      </q-card>
      <q-card class="q-mt-sm">
        <q-step
          class="q-pl-ms"
          :name="2"
          title="Huidige situatie vaststellen"
          icon="maps_home_work"
          :done="step > 2"
          :header-nav="step > 2 && !readonly"
        >
          <current-consumption style="margin-left: -36px" />
        </q-step>
      </q-card>
      <q-card class="q-mt-sm">
        <q-step
          :name="3"
          title="Aanbod selecteren"
          icon="local_offer"
          :done="step > 3"
          :header-nav="step > 3 && !readonly"
        >
          <offers style="margin-left: -36px" />
        </q-step>
      </q-card>
      <q-card class="q-mt-sm">
        <q-step
          :name="4"
          title="Opt-in"
          icon="add"
          :done="step > 4"
          :header-nav="step > 4 && !readonly"
        >
          <EnergyPlus />
        </q-step>
      </q-card>
      <q-card class="q-mt-sm">
        <q-step
          :name="5"
          title="Prospectgegevens Invullen"
          icon="person"
          :done="step > 5"
          :header-nav="step > 5 && !readonly"
        >
          <prospect-information />
        </q-step>
      </q-card>
      <q-card class="q-mt-sm">
        <q-step
          :name="6"
          title="Samenvatting"
          icon="feed"
          :done="step > 6"
          :header-nav="step > 6 && !readonly"
        >
          <voicelog />
        </q-step>
      </q-card>
      <q-card class="q-mt-sm">
        <q-step
          :name="7"
          title="Aanbieding verzenden"
          icon="attach_email"
          :done="step > 7"
          :header-nav="step > 7 && !readonly"
        >
          <preview />
        </q-step>
      </q-card>
    </q-stepper>
    <overview class="col" />
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useQuasar, Dialog } from "quasar";
import {
  cc_Enabled,
  cc_IsEnabled,
  cc_GotoPage,
} from "../plugins/cc-integration.js";

import Address from "../components/Address.vue";
import Overview from "../components/Overview.vue";
import CurrentConsumption from "../components/CurrentConsumption.vue";
import Offers from "../components/Offers.vue";
import ProspectInformation from "../components/ProspectInformation.vue";
import EnergyPlus from "../components/EnergyPlus.vue";
import Voicelog from "../components/Voicelog.vue";
import Preview from "../components/Preview.vue";

//* VueUse
const $q = useQuasar();
const $store = useStore();

//* Computed
const readonly = computed(
  () => ($store.getters.record?.locked || $store.getters.viewMode) ?? false
);

const recordStatus = computed(() => $store.getters.record?.Status_ID ?? 1);

const certifiedPartner = computed(
  () => $store.getters.record?.Certified_Partner ?? null
);

const afboekenVisible = computed(() => {
  console.log(cc_Enabled.value);
  if (!cc_Enabled.value) {
    return false;
  }
  if (
    recordStatus.value == 1 ||
    recordStatus.value == 13 ||
    recordStatus.value == 154 ||
    recordStatus.value == 2 ||
    recordStatus.value == 3
  ) {
    return false;
  }
  return true;
});

const terugVisible = computed(() => {
  console.log(cc_Enabled.value);
  if (!cc_Enabled.value) {
    return false;
  }
  if (readonly.value) {
    return false;
  }
  if (
    recordStatus.value == 1 ||
    recordStatus.value == 13 ||
    recordStatus.value == 154
  ) {
    return true;
  }
  if (recordStatus.value == 3) {
    return false;
  }
  return false;
});

const step = computed({
  get() {
    if (typeof $store.getters.record?.current_step != "number") {
      return parseInt($store.getters.record?.current_step);
    }
    return $store.getters.record?.current_step || 1;
  },
  set(newVal) {
    $store.dispatch("setCurrentStep", newVal);
  },
});

const colSize = computed(() => {
  return $q.screen.lt.md ? "col-12 q-pr-md" : "col-10";
});

//* Methods
function terug() {
  console.log(cc_Enabled.value);
  if (!cc_Enabled.value) {
    return;
  }
  cc_GotoPage("LaadRecord");
}

function afboeken() {
  if (!cc_Enabled.value) {
    return;
  }
  if (
    recordStatus.value == 1 ||
    recordStatus.value == 2 ||
    recordStatus.value == 3 ||
    recordStatus.value == 13 ||
    recordStatus.value == 29 ||
    recordStatus.value == 154
  ) {
    const message =
      "Let op! Je hebt het aanbod niet verstuurd en gaat het record niet positief afboeken. Dit record wordt niet voor verificatie aangeboden bij TQIS.\r\nJe kunt nog een terugbelafspraak maken. Weet je zeker dat je verder wilt gaan?";
    Dialog.create({
      title: "Confirm",
      message,
      cancel: true,
      persistent: true,
    }).onOk(() => {
      cc_GotoPage("portalclosednegative");
    });
  } else if (
    recordStatus.value == 14 ||
    recordStatus.value == 148 ||
    recordStatus.value == 145 ||
    recordStatus.value == 17 ||
    recordStatus.value == 15 ||
    recordStatus.value == 19 ||
    recordStatus.value == 18 ||
    recordStatus.value == 6 ||
    recordStatus.value == 7 ||
    recordStatus.value == 117 ||
    recordStatus.value == 151 ||
    recordStatus.value == 118 ||
    recordStatus.value == 143 ||
    recordStatus.value == 142 ||
    recordStatus.value == 145 ||
    recordStatus.value == 107 ||
    recordStatus.value == 118 ||
    recordStatus.value == 173 ||
    recordStatus.value == 176 ||
    recordStatus.value == 179 ||
    recordStatus.value == 185
  ) {
    cc_GotoPage("portalclosedpositive");
  }
}

//* Lifecycle
onMounted(() => {
  // $store.dispatch("restartPolls");
  setTimeout(() => {
    cc_IsEnabled();
  }, 3000);
});
</script>

<style lang="sass"></style>
