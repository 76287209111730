<template>
  <div class="q-pb-md row" style="margin-left: -36px">
    <HMSVerification class="col-12" v-if="d2d && d2d != 'false'" />
    <q-slide-transition>
      <div
        :class="colSize"
        v-html="voicelog"
        v-show="
          hasVoicelogStarted == 1 &&
          (!d2d || d2d == 'false') &&
          typeof voicelog === 'string'
        "
      ></div>
    </q-slide-transition>
    <div
      class="col-12"
      v-show="viewMode || (hasVoicelogStarted == 1 && (!d2d || d2d == 'false'))"
    >
      <StepperNavigation
        class="row justify-end"
        @next-step="nextStep()"
        @previous-step="previousStep()"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useQuasar, date } from "quasar";
import {
  cc_Enabled,
  cc_IsEnabled,
  cc_RecordingStatus,
  cc_RecordingStart,
  cc_RecordingStop,
  cc_RecordingName,
} from "../plugins/cc-integration.js";

import HMSVerification from "./HMSVerification.vue";
import StepperNavigation from "@/components/StepperNavigation.vue";

//* Props
// eslint-disable-next-line no-undef
const props = defineProps({
  settings: Object,
});

//* VueUse
const $store = useStore();
const $q = useQuasar();

//* Refs
const voicelogTimer = ref(0);
const voicelogTimerLimit = ref(props?.settings?.timer ?? 80); // Als de default van SAC ook aangepast moet worden moet dit op lijn 212
const voicelogStarted = ref(-1);
const checkIfVoicelogHasStartedPoller = ref(null);
const useTemplateEngine = ref(props?.settings?.useTemplateEngine ?? false);

//* Computed
const viewMode = computed(() => $store.getters.viewMode);
const view = computed(() => $store.getters.view);
const voicelog = computed(() => $store.getters.voicelog);
const d2d = computed(() => $store.getters.projectSpecific.D2D ?? false);
const colSize = computed(() => ($q.screen.lt.md ? "col-12" : "col-6"));
const hasVoicelogStarted = computed(() => voicelogStarted.value);
const recordStatus = computed(() => {
  return (
    $store.getters.record?.Status_ID ?? $store.getters.record?.status_id ?? 1
  );
});
const recordLocation = computed(() => {
  return (
    $store.getters.record?.External_locatie ??
    $store.getters.steamVelden?.locatie ??
    "1"
  );
});

//* Methods
const setVoicelogStartedTrue = () => {
  console.log("voicelogStarted1");
  voicelogStarted.value = 1;
};
const setVoicelogStartedFalse = () => {
  console.log("voicelogStarted0");
  voicelogStarted.value = 0;
};
const setVoicelogStartedUndef = () => {
  console.log("voicelogStarted-1");
  voicelogStarted.value = -1;
};
const startVoicelog = () => {
  if (
    cc_RecordingStatus() != 2 &&
    (recordStatus.value == 1 ||
      recordStatus.value == 3 ||
      recordStatus.value == 154)
  ) {
    console.log("In vue-startvoicelog function option 1");
    setVoicelogStartedFalse();
    cc_RecordingStart("", () => {}, null, "");
  } else if (cc_RecordingStatus() == 2) {
    return;
  } else {
    console.log("In vue-startvoicelog function option 2");
    setVoicelogStartedFalse();
    const message = "De voicelog is niet correct opgenomen, start opnieuw";
    $q.notify({
      type: "negative",
      message,
      position: "top",
    });
    previousStep();
  }
};
const previousStep = () => {
  if (
    cc_Enabled.value &&
    cc_RecordingStatus() != 0 &&
    cc_RecordingStatus() != 3
  ) {
    setVoicelogStartedUndef();
    cc_RecordingStop(() => {});
    setTimeout(previousStep, 3000);
    return;
  }
  $store.dispatch("decrementCurrentStep");
  $store.dispatch("updateStatus", {
    status: 1,
    reason: "",
  });
};
const checkIfVoicelogHasStarted = () => {
  const status = cc_RecordingStatus();
  const name = cc_RecordingName();
  if (status == 1) {
    return;
  } else if (status == 3) {
    $q.notify({
      type: "negative",
      message:
        "Je hebt een actieve telefoonverbinding nodig om naar de voicelog te kunnen gaan",
      position: "top",
    });
    clearInterval(checkIfVoicelogHasStartedPoller.value);
    previousStep();
  } else if (
    status != 2 ||
    name.substring(0, 8) != date.formatDate(Date.now(), "YYYYMMDD")
  ) {
    $q.notify({
      type: "negative",
      message: "De voicelog is niet correct opgenomen, start opnieuw.",
      position: "top",
    });
    clearInterval(checkIfVoicelogHasStartedPoller.value);
    previousStep();
  } else {
    console.log("Voicelog gestart");
    voicelogStarted.value = 1;

    $store.dispatch("setProjectSpecificValue", {
      key: "CC_RDVoicelogName1",
      value: name,
    });
    clearInterval(checkIfVoicelogHasStartedPoller.value);
  }
};

const nextStep = () => {
  if (viewMode.value === true) {
    $store.dispatch("incrementCurrentStep");
    return;
  }
  if (cc_Enabled.value && cc_RecordingStatus() != 0) {
    cc_RecordingStop(() => {});
    setTimeout(nextStep, 3000);
    return;
  }
  if (
    voicelogTimer.value <= voicelogTimerLimit.value &&
    recordLocation.value.includes("TK_")
  ) {
    $q.notify({
      type: "negative",
      message: "De voicelog is niet correct opgenomen, start opnieuw",
      position: "top",
    });
    previousStep();
    return;
  }
  if (cc_Enabled.value && hasVoicelogStarted.value !== 1) {
    $q.notify({
      type: "negative",
      message: "De voicelog is niet correct opgenomen, start opnieuw",
      position: "top",
    });
    previousStep();
    return;
  }
  $store.dispatch("incrementCurrentStep");
  $store.dispatch("updateStatus", {
    status: 29,
    reason: "Voicelog is afgerond",
  });
};

//* Conditions
if (view.value == "retention") {
  voicelogTimerLimit.value = 80;
}
setInterval(() => {
  voicelogTimer.value += 1;
}, 1000);

$store.dispatch("getContent", {
  text: 2,
  useTemplateEngine: useTemplateEngine.value,
});

//* Lifecycle
onMounted(() => {
  if (viewMode.value === true) {
    return;
  }
  setTimeout(() => {
    cc_IsEnabled();
    if (cc_Enabled.value && [1, 3, 154].includes(recordStatus.value)) {
      startVoicelog();
      checkIfVoicelogHasStartedPoller.value = setInterval(
        checkIfVoicelogHasStarted,
        500
      );
    } else {
      setVoicelogStartedTrue();
    }
  }, 1000);
});
</script>

<style lang="sass">
@import '../styles/quasar.sass'
th
  font-weight: 700 !important
.consumption-card
  width: 90%
  margin: auto
.provider
  opacity: 0.4
  transition: 0.2s
.provider:hover
  opacity: 1
  transition: 0.1s
.selectedProvider
  transition: 0.2s
.icon
    font-size: 40px
    position: relative
    z-index: 2
    margin: auto
    width: 90px
    height: 90px
    line-height: 90px
    border-radius: 100%
    background-image: linear-gradient(to bottom, $primary, $secondary)
    vertical-align: middle
    border: none
</style>
