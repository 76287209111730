import script from "./Voicelog.vue?vue&type=script&setup=true&lang=js"
export * from "./Voicelog.vue?vue&type=script&setup=true&lang=js"

import "./Voicelog.vue?vue&type=style&index=0&id=41f36c41&lang=sass"

const __exports__ = script;

export default __exports__
import QSlideTransition from 'quasar/src/components/slide-transition/QSlideTransition.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSlideTransition});
